import CommercialContent from '../../components/CommercialContent';
import { firebaseDb } from '../../config/firebase';
import { withRouter } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import './index.css';

import React, { Component } from 'react';

class Commercial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      hideFullScreen: true,
    };

    this.timeout = null;
  }

  componentDidMount() {
    const commercials = firebaseDb.collection('commercials');
    this.setIdleTimeout();

    window.addEventListener('mousemove', this.resetTimer);
    window.addEventListener('scroll', this.resetTimer);

    let data = [];
    commercials
      .get()
      .then((snapshot) => {
        snapshot.forEach(doc => {
          data.push(doc.data())
          this.setState({
            data
          })
        });
      })
  }

  resetTimer = () => {
    clearTimeout(this.timeout);
    this.setState({
      hideFullScreen: false,
    });

    this.setIdleTimeout();
  }

  setIdleTimeout = () => {
    this.timeout = setTimeout(() => {
      this.setState({
        hideFullScreen: true
      })
    }, 2500);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    window.removeEventListener('mousemove', this.resetTimer);
    window.removeEventListener('scroll', this.resetTimer);
  }

  render() {
    const { data, hideFullScreen } = this.state;
    const { match } = this.props;

    const activeContent = data && data.filter(item => item.category === match.params.section)[0];

    return data ? (
      <div className="Commercial">
        {activeContent && <CommercialContent
          hideFullScreen={hideFullScreen}
          items={activeContent.items}
        />
        }
      </div>) : <Spinner />;
  }
}

export default withRouter(Commercial);