import React from 'react';
import { ReactComponent as SpinnerSvg } from '../../assets/images/circle.svg';
import cx from 'classnames';

import './index.css';

const Spinner = ({ image }) => {
  const spinnerClasses = cx('spinner', image && 'spinner--big');
  return (
    <div className={spinnerClasses}>
      {image && <div style={{ backgroundImage: `url(${image})` }} alt='loader' className='spinner-image' />}
      {image ? <SpinnerSvg className='spinner-svg' /> : <div className="spinner-container">
        <div className="spinner-rotator">
          <div className="spinner-left">
            <div className="spinner-circle" />
          </div>
          <div className="spinner-right">
            <div className="spinner-circle" />
          </div>
        </div>
      </div>
      }
    </div>
  )
}

export default Spinner;