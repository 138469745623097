import React, { useState, useEffect } from 'react';
import { setLoader } from '../../actions';
import { connect } from "react-redux";
import { firebaseDb } from '../../config/firebase';
import './Contact.css';

const Contact = ({ dispatch }) => {
  const [mail, setEmail] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setLoader(false))
    }, 1500);
    const biography = firebaseDb.collection('contact');
    biography.get()
      .then((snapshot) => {
        snapshot.forEach(doc => {
          setEmail(doc.data().email)
        })
      })
  }, [dispatch])
  return (
    <div className="Contact">
      <a href={`mailto:${mail}`}>{mail}</a>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    isLoaderVisible: state.isLoaderVisible
  };
}

export default connect(mapStateToProps)(Contact);
