import React, { Component } from 'react';
import SingleFilmProject from '../../components/SingleFilmProject';
import { withRouter } from 'react-router-dom';
import { firebaseDb } from '../../config/firebase';

class FilmProject extends Component {
  state = {
    data: null
  };

  componentDidMount() {
    const film = firebaseDb.collection('film');
    let data = [];
    film
      .get()
      .then((snapshot) => {
        snapshot.forEach(doc => {
          data.push(doc.data())
          this.setState({
            data
          })
        });
      })
  }

  render() {
    const filmProject = this.props.match.params.project;
    const data = this.state.data &&
      this.state.data.find(item => item.name.split(' ').join('_').toLowerCase() === filmProject);
    return <SingleFilmProject data={data} />
  }
}

export default withRouter(FilmProject);