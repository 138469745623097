import React, { useState } from 'react';
import AdminEditor from '../AdminEditor';

import './index.css';

const AdminPagePicker = () => {
  const [activePage, setActivePage] = useState(null);
  const pages = ['Home', 'Biography', 'Commercials', 'Photography'];

  const renderPagePicker = () => {
    return pages.map(page => (
      <button
        className='AdminButton'
        onClick={() => setActivePage(page)}
        key={page}
      >
        {page}
      </button>
    ))
  }

  const renderActivePage = () => {
    const pickedPage = pages.filter(page => page === activePage)[0];
    return <AdminEditor page={pickedPage} />
  }

  return (
    <div className="AdminPagePicker">
      {
        activePage ?
          <div>
            <button onClick={() => setActivePage(null)}>Go back</button>
            {renderActivePage()}</div> :
          renderPagePicker()
      }
    </div>
  );
}

export default AdminPagePicker;
