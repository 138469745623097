import React, { useState, useEffect } from 'react';
import { firebaseDb } from '../../config/firebase';
import HomeLinks from '../../components/HomeLinks';
import './index.css';

const Film = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const filmsDb = firebaseDb.collection('film');
    filmsDb.get()
      .then((snapshot) => {
        let data = []
        snapshot.forEach(doc => {
          data.push(doc.data())
        })
        setData(data);
      })
  }, []);

  return (
    <div className="Film">
      <HomeLinks isFilm handlePageChange={props.handlePageChange} categories={data} />
    </div>
  );
}

export default Film;