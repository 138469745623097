import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { firebaseDb } from '../../config/firebase';
import Photo from '../../components/PhotographyList/Photo';
import './PhotoProject.css';

class PhotoProject extends Component {
  state = {
    data: null,
  };

  componentDidMount() {
    const photography = firebaseDb.collection('photography');
    let data = [];
    photography
      .get()
      .then((snapshot) => {
        snapshot.forEach(doc => {
          data.push(doc.data())
          this.setState({
            data
          })
        });
      })
  }

  render() {
    const project = this.props.match.params.project;
    const data = this.state.data && this.state.data.find(item => item.title === project);
    return (
      <div className='PhotoProject'>
        <Photo data={data} />
      </div>
    )
  }
}

export default withRouter(PhotoProject);