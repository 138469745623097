import React, { Component } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import Spinner from '../Spinner';
import { ReactComponent as FullScreenSvg } from '../../assets/images/fs.svg';
import cx from 'classnames';

import './index.css';

class Video extends Component {

  constructor(props) {
    super(props);

    this.state = {
      _isPlaying: false,
      isVideoPlayed: null,
    }

    this.videoRef = React.createRef();
  }

  // Called when the video starts for the very first time.

  handleOnPlay = () => {
    this.setState({
      isVideoPlayed: true,
    })
  }

  handleVideoEnd = () => {
    const { onEnd } = this.props;
    onEnd();
  }

  componentDidUpdate(prevProps) {
    // When we change source, even on same page, let's reset video play so we can see 'Loading' again
    if (prevProps.source !== this.props.source) {
      this.setState({
        isVideoPlayed: false
      })
    }
  }

  handleOverlayClick = () => {
    const {
      onPlayClick,
      onPauseClick,
      isPlaying,
      localControls,
    } = this.props;

    const { isVideoPlayed } = this.state;

    if (localControls) {
      this.toggleOverlay();
    } else {
      if (isVideoPlayed && isPlaying) {
        onPauseClick();
      } else {
        onPlayClick();
      }
    }
  }

  toggleOverlay = () => {
    this.setState({
      _isPlaying: !this.state._isPlaying,
    })
  }

  toggleFullScreen = () => {
    const fullscreenElement = document.fullscreenElement || document.webkitCurrentFullScreenElement;
    if (!fullscreenElement) {
      const rfs =
        this.videoRef.current.requestFullscreen ||
        this.videoRef.current.webkitEnterFullscreen ||
        this.videoRef.current.webkitRequestFullScreen ||
        this.videoRef.current.mozRequestFullScreen ||
        this.videoRef.current.msRequestFullscreen;
      rfs.call(this.videoRef.current);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  render() {
    const {
      className,
      source,
      isPlaying,
      localControls,
      hideFullScreen,
      label,
      onLoaded,
      shouldFade,
    } = this.props;

    const {
      isVideoPlayed,
      _isPlaying,
    } = this.state;
    const isPlayingTruth = localControls ? _isPlaying : isPlaying;
    const wrapperClasses = cx('Video-wrapper', className);
    const overlayClasses = cx('Video-overlay', isPlayingTruth && isVideoPlayed && 'Video-overlay--hidden', shouldFade && 'Video-overlay--faded');
    const fsClasses = cx('Video-button-full', hideFullScreen && 'Video-button-full--hidden');
    let videoLabel = !isPlayingTruth && 'Play';
    if (label) {
      videoLabel = !isPlayingTruth && label;
    }

    return (
      <div
        className={wrapperClasses}
        ref={this.videoRef}
      >
        <Vimeo
          paused={!isPlayingTruth}
          onEnd={this.handleVideoEnd}
          controls={false}
          className='Video-player'
          video={source}
          onLoaded={onLoaded}
          autopause={false}
          onPlay={this.handleOnPlay}
        />
        <div
          onClick={this.handleOverlayClick}
          className={overlayClasses}
        >
          {isPlayingTruth && !isVideoPlayed && <Spinner />}
          <button className='Video-button-play'>{videoLabel}</button>
        </div>
        <button
          onClick={() => this.toggleFullScreen()}
          className={fsClasses}
        >
          <FullScreenSvg />
        </button>
      </div>
    )
  }
}

export default Video;