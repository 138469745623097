import React from 'react';
import cx from 'classnames';
import './index.css';
import HeaderLink from './HeaderLink';
import { withRouter } from 'react-router-dom';

function Header(props) {

  const menuRoutes = [
    {
      name: 'Action',
      link: '/commercial/action',
    },
    {
      name: 'Comedy',
      link: '/commercial/comedy',
    },
    {
      name: 'Children',
      link: '/commercial/children',
    },
    {
      name: 'Health & Beauty',
      link: '/commercial/health&beauty',
    },
    {
      name: 'Film',
      link: '/film',
    },
    {
      name: 'Photography',
      link: '/photography',
    },
    {
      name: 'Contact',
      link: '/contact'
    }
  ]

  const photographyRoutes = [
    {
      name: 'Shades',
      link: '/photo-project/shades'
    },
    {
      name: 'Summer',
      link: '/photo-project/summer'
    },
    {
      name: 'Abstract',
      link: '/photo-project/abstract'
    },
  ]

  const filmRoutes = [
    {
      name: "Glare",
      link: '/film-project/glare'
    },
    {
      name: "Operation Cold Amber",
      link: '/film-project/operation_cold_amber'
    },
    {
      name: "Honey",
      link: '/film-project/honey'
    },
    {
      name: "The Neighbour",
      link: '/film-project/the_neighbour'
    }
  ]

  const renderNavigationLinks = () => {
    const { location: { pathname }, handlePageChange } = props;
    const isOnFilmOrPhotography = pathname.includes('film-project') || pathname.includes('photo-project')
    const hasFilmOrPhotographyId = pathname.split('/').length === 3 && pathname.split('/')[2] !== ''
    const isOnSingleProjectPage = isOnFilmOrPhotography && hasFilmOrPhotographyId;
    const pathNameWitOutLastDash = pathname[pathname.length - 1] === '/' ? pathname.substring(0, pathname.length - 1) : pathname;
    if (pathname === '/') {
      return (
        <>
          <HeaderLink showAlways handlePageChange={handlePageChange} label='Bio' to='/biography' />
          <HeaderLink showAlways handlePageChange={handlePageChange} label='Contact' to='/contact' />
        </>
      )
    } else if (pathname === '/contact') {
      return <HeaderLink showAlways isActive handlePageChange={handlePageChange} label='Contact' to='/contact' />
    } else if (menuRoutes.some(route => {
      const hasRoutes = route.link === pathNameWitOutLastDash;
      return hasRoutes;
    })) {
      return menuRoutes.map((item, index) => {
        return <HeaderLink
          isActive={item.link === pathNameWitOutLastDash}
          key={index}
          handlePageChange={handlePageChange}
          label={item.name}
          to={item.link}
        />
      })
    } else if (pathname === '/biography') {
      return <HeaderLink showAlways handlePageChange={handlePageChange} label='Home' to='/' />
    } else if (isOnSingleProjectPage) {
      if (pathname.includes('photo-project')) {
        return photographyRoutes.map((route, index) => {
          const activeRoute = pathname === route.link;
          return <HeaderLink
            handlePageChange={handlePageChange}
            isActive={activeRoute}
            to={route.link}
            key={index}
            label={route.name}
          />
        })
      }
      if (pathname.includes('film-project')) {
        return filmRoutes.map((route, index) => {
          const activeRoute = pathname === route.link;
          return <HeaderLink
            handlePageChange={handlePageChange}
            isActive={activeRoute}
            to={route.link}
            key={index}
            label={route.name}
          />
        })
      }
    }
  }
  const { setIsMobileMenuActive, isMobileMenuActive } = props;
  const mobileMenuToggleClasses = cx(
    'Header-mobileMenu-toggle',
    isMobileMenuActive && 'Header-mobileMenu-toggle--active'
  )
  return (
    <div className='Header'>
      <div className='Header-top'>
        <button className='Header-title' onClick={() => props.handlePageChange('')}>Thomas Krygier</button>
        <button className={mobileMenuToggleClasses} onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}>
          <span />
          <span />
          <span />
        </button>
      </div>
      <div className="Links">
        {renderNavigationLinks()}
      </div>
    </div >
  );
}

export default withRouter(Header);
