import React, { useState, useRef } from 'react';
import './index.css';

import { firebaseAuth } from '../../config/firebase';

const Login = () => {
  const [error, setError] = useState('');

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const logIn = () => {
    const emailInputValue = emailRef.current.value;
    const passwordInputValue = passwordRef.current.value;
    const shouldAllowLogin = emailInputValue.length > 0 && passwordInputValue.length > 0;
    if (shouldAllowLogin) {
      const promise = firebaseAuth.signInWithEmailAndPassword(emailInputValue, passwordInputValue);
      promise
        .catch(e => {
          setError('Error happened, please try again!');
        });
    } else {
      setError('Write something in email/password fields');
    }
  }

  return (
    <div className="Login">
      <h3>Log in</h3>
      <div className="Login-form">
        <input
          autoComplete="new-password"
          className="Login-input"
          ref={emailRef}
          type='text'
          placeholder='email' />
        <input
          autoComplete="new-password"
          className="Login-input"
          ref={passwordRef}
          type='password'
          placeholder='password' />
        <p className="Login-error">{error}</p>
      </div>
      <button className="Login-button" onClick={() => logIn()}>Log in</button>
    </div>
  );
}


export default Login;