import React, { useState, useEffect } from 'react';
import PhotographyList from '../../components/PhotographyList';
import { firebaseDb } from '../../config/firebase';

import './index.css';

const Photography = ({ handlePageChange }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const photographyDb = firebaseDb.collection('photography');
    let data = []
    photographyDb.get()
      .then((snapshot) => {
        snapshot.forEach(doc => {
          data.push(doc.data())
        })
        setData(data);
      })
  }, [])

  return (
    <div className="Photography">
      <PhotographyList handlePageChange={handlePageChange} data={data} />
      <p className='Photography-description'>
        Following into the footsteps of his favourite photographer Richard Avedon, Thomas' first job as a photographer was a global campaign for Levi's. In New York, creative director and graphic designer legend Tibor Kalman hired Thomas to shoot for Interview magazine. Back in London Thomas became a regular contributor shooting for THE FACE and many other international magazines.
      </p>
    </div>
  );
}

export default Photography;