import React, { useState, useEffect } from 'react';
import Login from '../../components/Login';
import AdminPagePicker from '../../components/AdminPagePicker';
import { firebaseAuth } from '../../config/firebase';

import './index.css';

const Admin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {
    const fireBaseListener = firebaseAuth.onAuthStateChanged(firebaseUser => {
      setIsLoggedIn(firebaseUser ? true : false);
    })
    return () => {
      fireBaseListener();
    }
  })

  const renderAdminHeader = () => {
    return (
      <div className="Admin-Header">
        <span>Welcome</span>
        <button onClick={onSignOutClick}>Sign Out</button>
      </div>
    )
  }

  const onSignOutClick = () => {
    firebaseAuth.signOut();
  }

  const renderAdmin = () => {
    return <>
      {renderAdminHeader()}
      <AdminPagePicker />
    </>
  }

  return (
    <div className="Admin">
      {isLoggedIn ? renderAdmin() : <Login />}
    </div>
  );
}

export default Admin;
