import React, { } from 'react';
import { setLoader } from '../../../actions';
import { connect } from "react-redux";
import './LandscapeBckgImg.css'

const LandscapeBckgImg = ({ src, dispatch }) => {
  return (
    <div className="LandscapeBckgImg">
      <img src={src} alt='biography' onLoad={() => setTimeout(() => {
        dispatch(setLoader(false))
      }, 1500)} />
    </div>
  )
}


function mapStateToProps(state) {
  return {
    isLoaderVisible: state.isLoaderVisible
  };
}

export default connect(mapStateToProps)(LandscapeBckgImg);