import React from 'react';
import './index.css';
import cx from 'classnames';

function HeaderLink({ handlePageChange, to, label, isActive, showAlways }) {
  const btnClasses = cx(
    'HeaderLink',
    showAlways && 'HeaderLink--visible',
    isActive && 'HeaderLink--active',
  );
  return (
    <button className={btnClasses} onClick={() => handlePageChange(to)}>{label}</button>
  );
}

export default HeaderLink;
