import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { firebaseDb } from '../../config/firebase';
import './index.css';

const dbNames = {
  Home: 'homelinks',
  Biography: 'biography',
  Commercials: 'commercials',
  Photography: 'photography'
}

class AdminEditor extends Component {

  state = {
    fields: null,
    formDirty: false,
    values: {},
    showAlert: false,
    alertMessage: null,
    quillValue: null,
  }

  // on mount - add LISTENER to listen for data changes.
  // this is different than in HOME page, where we don't listen, we fetch data only once.
  // each time something happens, we push content to `fields` property in our state.

  componentDidMount() {
    const { page } = this.props;
    const activeCollection = firebaseDb.collection(dbNames[page]);
    activeCollection
      .onSnapshot((snapshot) => {
        const localFields = [];
        snapshot.forEach((doc) => {
          localFields.push(doc)
          this.setState({
            fields: localFields,
          })
          if (page === 'Biography') {
            this.setState({
              quillValue: doc.data().text
            })
          }
        })
      });
  }

  // show alert, and hide it after three seconds.

  handleAlert = (message) => {
    this.setState({
      showAlert: true,
      alertMessage: message,
    }, () => {
      setTimeout(() => {
        this.setState({
          showAlert: false,
          alertMessage: null,
        })
      }, 3000);
    })
  }

  // each time someone touch input, we push that value to `values` field.
  // We also set `formDirty` to true (therefore buttons will show),
  // which won't always be a case, but but no really time right now for that... :) 

  handleChange = (event, id, type) => {
    const newValues = {
      ...this.state.values,
      [id]: {
        ...this.state.values[id],
        [type]: event.target.value,
      }
    }
    this.setState({
      formDirty: true,
      values: newValues
    })
  }

  // On Update click we take `values` from state

  onUpdateClick = () => {
    const { values } = this.state;
    const { page } = this.props;
    const activeCollection = firebaseDb.collection(dbNames[page]);
    const keys = Object.keys(values);
    // and then for each changed KEY
    keys.map(key => {
      const docToChange = activeCollection.doc(key);
      // we Update that key on firebase with new values
      return docToChange.update(values[key])
        .then(() => {
          // then we add alert
          this.handleAlert('Success')
          // and then we 'reset' state
          this.setState({
            values: {},
            formDirty: false,
          })
        })
        .catch((e) => {
          console.log(e);
          this.handleAlert('Fail')
        })
    })
  }


  // we just remove update and cancel buttons from the dom.
  // ideally this would also restart inputs but no really time right now for that... :) 

  onCancelClick = () => {
    this.setState({
      formDirty: false,
    })
  }

  fakeAdd = () => {
    // ovo sam dodao da koristim kao brzi nacin dodavanja kontenta na api/firebase.
    // bilo bi lepo da ne otkomentarisemo ovo dugme u renderu jer cemo da napravimo harikiri
    const url = firebaseDb.collection('commercials');

    url.add({
      category: 'comedy',
      items: [
        {
          title: 'title',
          video: 'url',
          image: 'url',
        },
        {
          title: 'title',
          video: 'url',
          image: 'url',
        },
      ]
    })
  }

  handleQuillChange = (value) => {
    // Oqhei6SAUI7OLbFGFJge je ID Gallery Texta
    const newValues = {
      ...this.state.values,
      Oqhei6SAUI7OLbFGFJge: {
        ...this.state.values['Oqhei6SAUI7OLbFGFJge'],
        text: value,
      }
    }
    this.setState({
      formDirty: true,
      quillValue: value,
      values: newValues
    })
  }

  renderContent() {
    const { fields, quillValue } = this.state;
    const { page } = this.props;
    if (page === 'Home') {
      return fields && fields.map((field, index) => {
        return (
          <div key={index}>
            <input placeholder='name' className='AdminInput' type='text' defaultValue={field.data().name} onChange={(event) => this.handleChange(event, field.id, 'name')} />
            <input placeholder='image' className='AdminInput' type='text' defaultValue={field.data().image} onChange={(event) => this.handleChange(event, field.id, 'image')} />
          </div>
        )
      })
    } else if (page === 'Biography') {
      return fields && <div>
        <ReactQuill value={quillValue} onChange={this.handleQuillChange} />
        <input placeholder='image' className='AdminInput' type='text' defaultValue={fields[0].data().image} onChange={(event) => this.handleChange(event, fields[0].id, 'image')} />
      </div>
    }
  }

  render() {
    const { formDirty, showAlert, alertMessage } = this.state;
    const { page } = this.props;
    return (
      <div className="AdminEditor">
        {showAlert && <div className='Alert'><span>{alertMessage}</span></div>}
        <h1 className='AdminEditor-title'>{page}</h1>
        <div className='AdminInput-wrapper'>{this.renderContent()}</div>
        {formDirty && <div className='AdminChange-buttons'>
          <button onClick={this.onUpdateClick} className='AdminButton AdminButton-update'>Update</button>
          <button onClick={this.onCancelClick} className='AdminButton AdminButton-cancel'>Cancel</button>
        </div>}
        {/* <button onClick={this.fakeAdd}>add</button> */}
      </div>
    );
  }
}

export default AdminEditor;
