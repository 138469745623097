import React from 'react';
import Description from '../shared/Description';
import { setLoader } from '../../actions';
import { connect } from "react-redux";
import './SingleFilm.css';

const SingleFilmProject = ({ data, dispatch }) => {
  return data ? (
    <div className="SingleFilm">
      <div className='SingleFilm-bckg'>
        <img
          onLoad={() => setTimeout(() => {
            dispatch(setLoader(false));
          }, 1500)}
          src={data.image}
          alt={data.description}
          className='SingleFilm-image'
        />
      </div>
      <Description text={data.description} />
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    isLoaderVisible: state.isLoaderVisible
  };
}

export default connect(mapStateToProps)(SingleFilmProject);