import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../views/Home';
import Admin from '../views/Admin';
import Bio from '../views/Bio';
import Film from '../views/Film';
import Photography from '../views/Photography';
import Commercial from '../views/Commercial';
import FilmProject from '../views/FilmProject';
import PhotoProject from '../views/PhotoProject';
import Contact from '../views/Contact';

const publicPath = '/';

export const routeCodes = {
  HOME: { path: publicPath, name: 'Home' },
  ADMIN: { path: `${publicPath}admin`, name: 'Admin' },
  BIO: { path: `${publicPath}biography`, name: 'Bio' },
  FILM: { path: `${publicPath}film`, name: 'Film' },
  PHOTOGRAPHY: { path: `${publicPath}photography`, name: 'Photography' },
  PHOTO_PROJECT: { path: `${publicPath}photo-project/:project`, name: 'Photo Project' },
  COMMERCIAL: { path: `${publicPath}commercial`, name: 'Commercial' },
  FILM_PROJECT: { path: `${publicPath}film-project/:project`, name: 'Film Project' },
  CONTACT: { path: `${publicPath}contact`, name: 'Contact' }
}

export default ({ handlePageChange }) => {
  return (
    <Switch>
      <Route exact path={routeCodes.HOME.path} render={() => <Home handlePageChange={handlePageChange} />} />
      <Route path={routeCodes.ADMIN.path} render={() => <Admin handlePageChange={handlePageChange} />} />
      <Route path={routeCodes.BIO.path} render={() => <Bio handlePageChange={handlePageChange} />} />
      <Route path={routeCodes.FILM.path} render={() => <Film handlePageChange={handlePageChange} />} />
      <Route path={routeCodes.PHOTOGRAPHY.path} render={() => <Photography handlePageChange={handlePageChange} />} />
      <Route path={`${routeCodes.COMMERCIAL.path}/:section`} render={() => <Commercial handlePageChange={handlePageChange} />} />
      <Route path={routeCodes.FILM_PROJECT.path} render={() => <FilmProject handlePageChange={handlePageChange} />} />
      <Route path={routeCodes.PHOTO_PROJECT.path} render={() => <PhotoProject handlePageChange={handlePageChange} />} />
      <Route path={routeCodes.CONTACT.path} render={() => <Contact handlePageChange={handlePageChange} />} />
    </Switch>
  )
}