import React, { Component } from 'react';
import Routes from './config/routes';
import cx from 'classnames';
import Header from './components/Header';
import MobileMenu from './components/MobileMenu';
import './App.css';
import { setLoader } from './actions';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Loader from './components/Loader';

import loaderaction from './assets/images/loaders/loaderaction.jpg';
import loaderchildren from './assets/images/loaders/loaderchildren.jpg';
import loadercomedy from './assets/images/loaders/loadercomedy.jpg';
import loaderhealth from './assets/images/loaders/loaderhealth.jpg';
import loaderphotography from './assets/images/loaders/loaderphotography.jpg';
import loaderfilm from './assets/images/loaders/loaderfilm.jpg';

class App extends Component {
  state = {
    isAnimating: false,
    isFading: false,
    isMobileMenuActive: false,
  }

  setIsMobileMenuActive = bool => {
    this.setState({
      isMobileMenuActive: bool
    })
  }

  setIsAnimating = bool => {
    this.setState({
      isAnimating: bool
    })
  }

  setIsFading = bool => {
    this.setState({
      isFading: bool
    })
  }

  componentDidMount() {
    const { history, dispatch } = this.props;
    const isOnAdminPage = history.location.pathname.includes('admin');
    if (isOnAdminPage) {
      dispatch(setLoader(false));
    }
  }

  handlePageChange = (page) => {
    const { history, dispatch } = this.props;
    const { isMobileMenuActive } = this.state;
    const currentPage = history.location.pathname;
    const pathNameWitOutLastDash =
      currentPage[currentPage.length - 1] === '/' ? currentPage.substring(0, currentPage.length - 1) : currentPage;

    const isOnHomePage = currentPage === '/' || (pathNameWitOutLastDash && pathNameWitOutLastDash.length === 0 && page === '/');
    const isOnHomePageAndWantsToGoToHomePage = page.includes === '/' && isOnHomePage;

    const isOnCommercial = pathNameWitOutLastDash.includes('commercial');
    const isGoingToCommercial = page.includes('commercial');

    const isOnPhotoProject = pathNameWitOutLastDash.includes('photo-project');
    const isGoingToPhotoProject = page.includes('photo-project');

    const isOnFilm = pathNameWitOutLastDash.includes('film');

    const isOnFilmProject = pathNameWitOutLastDash.includes('film-project');
    const isGoingToFilmProject = page.includes('film-project');

    const isOnBiography = pathNameWitOutLastDash.includes('biography');

    const isOnContact = pathNameWitOutLastDash.includes('contact');
    const isGoingToContact = page.includes('contact');

    const isOnPhotography = pathNameWitOutLastDash.includes('photography');

    const translateOutFadein =
      (isOnHomePage && (isGoingToCommercial || isGoingToContact)) ||
      (isOnFilm && !isOnFilmProject && isGoingToFilmProject) ||
      (isOnPhotography && isGoingToPhotoProject) ||
      (isOnBiography && isGoingToContact) ||
      (isOnFilm && isGoingToContact) ||
      (isOnPhotography && isGoingToContact);

    const fadeOutTranslateIn =
      ((isOnPhotoProject || isOnFilmProject) &&
        isOnHomePage) || (isOnCommercial && isOnHomePage) || ((isOnContact && (isOnHomePage || page === '/film' || page === '/photography' || page === '/biography')));

    const isOnCommercialsAndWantsToGoToCommercial = isGoingToCommercial && isOnCommercial;
    const isOnSinglePhotoAndWantsToGoToSinglePhoto = isGoingToPhotoProject && isOnPhotoProject;
    const isOnSingleFilmAndWantsToGoToSingleFilm = isOnFilmProject && isGoingToFilmProject;
    const isOnCommercialsAndWantsToGoToContact = isOnCommercial && isGoingToContact;
    const isOnSingleFilmAndWantsToGoToContact = isOnFilmProject && isGoingToContact;
    const isOnSinglePhotoAndWantsToGoToContact = isOnPhotoProject && isGoingToContact;
    const isOnContactAndWantsToGoToCommercial = isOnContact && isGoingToCommercial;

    const shouldFade =
      isOnCommercialsAndWantsToGoToCommercial ||
      isOnSingleFilmAndWantsToGoToSingleFilm ||
      isOnCommercialsAndWantsToGoToContact ||
      isOnSingleFilmAndWantsToGoToContact ||
      isOnSinglePhotoAndWantsToGoToContact ||
      isOnContactAndWantsToGoToCommercial ||
      isOnSinglePhotoAndWantsToGoToSinglePhoto;

    if (isMobileMenuActive) {
      this.setIsMobileMenuActive(false);
    }

    const TRANSLATE_TIMEOUT = 800;
    const FADE_TIMEOUT = 500;

    if (pathNameWitOutLastDash !== page && !isOnHomePageAndWantsToGoToHomePage) {
      if (translateOutFadein) {
        this.setIsAnimating(true);
        setTimeout(() => {
          dispatch(setLoader(true));
          history.push(page);
          this.setIsFading(true);
          this.setIsAnimating(false);
        }, TRANSLATE_TIMEOUT);
      } else if (fadeOutTranslateIn) {
        this.setIsFading(true);
        setTimeout(() => {
          this.setIsAnimating(true);
        }, 100);
        setTimeout(() => {
          dispatch(setLoader(true));
          history.push(page);
          this.setIsFading(false);
        }, FADE_TIMEOUT);
      } else {
        if (shouldFade) {
          this.setIsFading(true);
          setTimeout(() => {
            dispatch(setLoader(true));
            history.push(page);
          }, FADE_TIMEOUT);
        } else {
          this.setIsAnimating(true);
          setTimeout(() => {
            dispatch(setLoader(true));
            history.push(page);
          }, TRANSLATE_TIMEOUT);
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isMobileMenuActive } = this.state;
    const { isLoaderVisible } = this.props;

    if (prevProps.isLoaderVisible !== isLoaderVisible) {
      if (!isLoaderVisible) {
        this.setIsFading(false);
        this.setIsAnimating(false);
      }
    }



    if (prevState.isMobileMenuActive !== isMobileMenuActive) {
      const HTML = document.querySelector('html');
      const BODY = document.querySelector('body');

      if (isMobileMenuActive) {
        HTML.classList.add('no-scrollable');
        BODY.classList.add('no-scrollable');
      } else {
        HTML.classList.remove('no-scrollable');
        BODY.classList.remove('no-scrollable');
      }
    }
  }

  getLoaderImage = () => {
    const { history } = this.props;
    const path = history.location.pathname;

    const isAction = path.includes('/commercial/action');
    const isComedy = path.includes('/commercial/comedy');
    const isChildren = path.includes('/commercial/children');
    const isHealth = path.includes('/commercial/health&beauty');
    const isFilm = path.includes('/film') || path === '/';
    const isPhoto = path.includes('/photo') || path.includes('/biography') || path.includes('/contact');

    if (isAction) {
      return loaderaction;
    } else if (isComedy) {
      return loadercomedy;
    } else if (isChildren) {
      return loaderchildren;
    } else if (isHealth) {
      return loaderhealth;
    } else if (isFilm) {
      return loaderfilm;
    } else if (isPhoto) {
      return loaderphotography;
    }
  }

  render() {
    const { isAnimating, isFading, isMobileMenuActive } = this.state;
    const { isLoaderVisible } = this.props;
    const siteClasses = cx(
      'Site',
      isAnimating && 'Site--animating',
      isFading && 'Site--fading',
    );

    return (
      <div className="App">
        <MobileMenu handlePageChange={this.handlePageChange} isActive={isMobileMenuActive} />
        <Header
          isMobileMenuActive={isMobileMenuActive}
          handlePageChange={this.handlePageChange}
          setIsMobileMenuActive={this.setIsMobileMenuActive}
        />
        <div className={siteClasses}>
          <Routes handlePageChange={this.handlePageChange} />
        </div>
        {isLoaderVisible && <Loader image={this.getLoaderImage()} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoaderVisible: state.isLoaderVisible
  };
}

export default withRouter(connect(mapStateToProps)(App));