import React, { useState, useEffect } from 'react';
import LandscapeBckgImg from '../../components/shared/LandscapeBckgImg';
import Description from '../../components/shared/Description';

import { firebaseDb } from '../../config/firebase';

import './index.css';

const Bio = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const biography = firebaseDb.collection('biography');
    biography.get()
      .then((snapshot) => {
        snapshot.forEach(doc => {
          setData(doc.data())
        })
      })
  }, [])

  return data ? (
    <div className="Bio">
      <LandscapeBckgImg src={data.image} />
      <Description text={data.text} />
    </div>) :
    null;
}

export default Bio;
