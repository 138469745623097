import React, { Fragment, useState, useEffect } from 'react';
import cx from 'classnames';
import Video from '../../Video';
import { setLoader } from '../../../actions';
import { connect } from "react-redux";
import Description from '../../shared/Description';
import Spinner from '../../Spinner';
import './Photo.css';

const Photo = ({ data, dispatch }) => {
  const [loadedImages, setLoadedImages] = useState([]);
  const onLoad = index => {
    setLoadedImages([...loadedImages, index]);
    // magic number :OMG:
    if (loadedImages.length + 4 >= data.content.images.length) {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 1500);
    }
  }

  useEffect(() => {
    setLoadedImages([]);
  }, [data])

  const activeProject = data && data.title.toLowerCase();

  const photoClasses = cx('Photo', `Photo--${activeProject}`);

  return data ? (
    <div className={photoClasses}>
      <div className='Photo-wrapper'>
        {
          data.content.images.map((item, itemIndex) => {
            const divClasses = cx('Photo-div', item.isFullScreen && 'Photo-div--full', item.isFullHeight && 'Photo-div--fullHeight');
            const isArray = !item.description;
            return (
              <div key={itemIndex} className={divClasses}>
                {isArray ?
                  item.image.map((innerItem, innerItemIndex) => (
                    <Fragment key={innerItemIndex}>
                      <div
                        className='Photo-image Photo-image--small'
                        style={{ backgroundImage: `url(${innerItem.image})` }}
                      />
                      <img className='Photo-img' src={innerItem.image} alt='Thomas Krygier' />
                    </Fragment>
                  )) :
                  <Fragment>
                    {item.video ? <Video source={item.video} localControls className='Photo-image Photo-video'>
                      {
                        item.text && (
                          <Fragment>
                            <p className='Photo-image--text'>{item.text}</p>
                            <p className='Photo-image--left'>{item.left}</p>
                          </Fragment>
                        )
                      }
                    </Video> :
                      <>
                        <div style={{ backgroundImage: `url(${item.image})` }} className='Photo-image'>
                          {
                            item.text && (
                              <Fragment>
                                <p className='Photo-image--text'>{item.text}</p>
                                <p className='Photo-image--left'>{item.left}</p>
                              </Fragment>
                            )
                          }
                        </div>
                        <img className='Photo-img' onLoad={() => onLoad(itemIndex)} src={item.image} alt='Thomas Krygier' />
                      </>
                    }
                  </Fragment>
                }
              </div>
            )
          })
        }
      </div>
      <Description className='Photo-description' text={data.content.description} />
    </div>
  ) : <Spinner />;
}


function mapStateToProps(state) {
  return {
    isLoaderVisible: state.isLoaderVisible
  };
}

export default connect(mapStateToProps)(Photo);