import React from 'react';
import Spinner from '../Spinner';
import './index.css';

const Loader = ({ image }) => {
  return (
    <div className='Loader'><Spinner image={image} /></div>
  )
}

export default Loader;