import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import Video from '../Video';
import { setLoader } from '../../actions';
import { connect } from "react-redux";
import { ReactComponent as PlaySvg } from '../../assets/images/play.svg';

import './index.css';

const itemsVisible = 4;

const CommercialContent = ({ items, hideFullScreen, dispatch }) => {
  const [activeCommercialIndex, setActiveCommercialIndex] = useState(0);
  const [carouselPosition, setCarouselPosition] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [mobileVideoActive, setMobileVideoActive] = useState(null);

  // Each time array of items changes
  // let's set activeCommercialIndex to 0
  // That way we make sure that, for example, if we are on Action page
  // And want to go to Comedy, it will load first item(video) from that category
  // Because that is not basically a page change (commercial/action -> commercial/comedy)
  // We need useEffect to do that.
  // Added because some categories have more videos than other, and, for example
  // items[7] might be undefined and break our app.
  // Also set carousel position to start

  useEffect(() => {
    setActiveCommercialIndex(0);
    setCarouselPosition(0);
    setIsPlaying(false);
  }, [items]);

  const handleSliderItemClick = (index) => {
    if (index !== activeCommercialIndex) {
      setActiveCommercialIndex(index);
      setIsPlaying(false);
    }
  }

  const renderSliderItems = () => {
    return items.map((item, index) => {
      const itemClasses = cx('CommercialContent-item', {
        'CommercialContent-item--active': activeCommercialIndex === index,
      });

      return (
        <button onClick={() => handleSliderItemClick(index)} className={itemClasses} key={index}>
          <div className='CommercialContent-item-inner' style={{ backgroundImage: `url(${item.image})` }}>
            <div className='CommercialContent-overlay' />
            <div className="CommercialContent-inner">
              <span>{item.title}</span>
            </div>
          </div>
        </button>
      )
    });
  }

  const renderSlider = () => {
    return (
      <div className='CommercialContent-slider'>
        <div className='CommercialContent-slider-controls'>
          <button
            onClick={() => handleButtonClick('prev')}
            className='CommercialContent-slider-controls-button CommercialContent-slider-controls-button--back'
          >
            <PlaySvg className='CommercialContent-slider-controls-button--icon' />
          </button>
          <button
            onClick={() => handleButtonClick('forward')}
            className='CommercialContent-slider-controls-button'
          >
            <PlaySvg />
          </button>
        </div>
        <div className='CommercialContent-slider-items'>
          <div className='CommercialContent-slider-items-inner' style={{ transform: `translateX(-${carouselPosition * 25}%)` }}>
            {items && renderSliderItems()}
          </div>
        </div>
      </div>
    )
  }

  // classic Slider controls.
  // Move +-1, go to start/end if needed.

  const handleButtonClick = (direction) => {
    if (direction === 'prev') {
      setCarouselPosition(carouselPosition === 0 ?
        items.length - itemsVisible : carouselPosition - 1)
    } else {
      setCarouselPosition(carouselPosition === items.length - itemsVisible ?
        0 : carouselPosition + 1);
    }
  }

  const onPlayClick = () => {
    setIsPlaying(true);
  }

  // this function is called each time video ends
  const handleVideoEnd = () => {
    setIsPlaying(false);
    const newActiveCommercialIndex = activeCommercialIndex === items.length - 1 ? 0 : activeCommercialIndex + 1;
    setActiveCommercialIndex(newActiveCommercialIndex);

    setTimeout(() => {
      setIsPlaying(true);
    }, 300);
  }

  const onMobilePauseClick = () => {
    setMobileVideoActive(null);
  }

  const onMobilePlayClick = index => {
    setMobileVideoActive(index)
  }

  const onMobileVideoEnd = () => {
    setMobileVideoActive(null)
  }

  const onPauseClick = () => {
    setIsPlaying(false);
  }

  const renderMobile = () => {
    return items.map((item, index) => (
      <Video
        shouldFade={mobileVideoActive !== null && index !== mobileVideoActive}
        isPlaying={mobileVideoActive !== null && index === mobileVideoActive}
        label={item.title}
        className='CommercialContent-mobile-video'
        key={index}
        source={item.video}
        hideFullScreen={hideFullScreen}
        onPauseClick={onMobilePauseClick}
        onPlayClick={() => onMobilePlayClick(index)}
        onEnd={onMobileVideoEnd}
      />
    ))
  }

  const handleOnLoaded = () => {
    setTimeout(() => {
      dispatch(setLoader(false));
    }, 1500);
  }

  return (
    <div className="CommercialContent">
      <div className='CommercialContent-mobile'>
        {renderMobile()}
      </div>
      <div className='CommercialContent-big'>
        <Video
          hideFullScreen={hideFullScreen}
          onPauseClick={onPauseClick}
          onPlayClick={onPlayClick}
          onEnd={handleVideoEnd}
          isPlaying={isPlaying}
          source={items[activeCommercialIndex].video}
          onLoaded={handleOnLoaded}
        />
        {renderSlider()}
      </div>
    </div>
  );
}


function mapStateToProps(state) {
  return {
    isLoaderVisible: state.isLoaderVisible
  };
}

export default connect(mapStateToProps)(CommercialContent);