import React from 'react';
import cx from 'classnames';
import './Description.css';

const Description = (props) => {
  const classes = cx('Description-wrapper', props.className);
  return (
    <div className={classes}>
      <p className='description' dangerouslySetInnerHTML={{ __html: props.text }} />
    </div>
  )
}

export default Description;