import React, { useState, useEffect } from 'react';
import { firebaseDb } from '../../config/firebase';
import HomeLinks from '../../components/HomeLinks';

import './index.css';

const Home = ({ handlePageChange }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const homelinks = firebaseDb.collection('homelinks');
    homelinks.get()
      .then((snapshot) => {
        let data = []
        snapshot.forEach(doc => {
          data.push(doc.data())
        })
        setData(data);
      })
  }, []);

  return (
    <div className="Home">
      <HomeLinks categories={data} handlePageChange={handlePageChange} />
    </div>
  );
}

export default Home;
