import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAfKNg4xkkV5ttktGvp4uyjI0_L8AIPXes",
  authDomain: "thomas-75f09.firebaseapp.com",
  databaseURL: "https://thomas-75f09.firebaseio.com",
  projectId: "thomas-75f09",
  storageBucket: "",
  messagingSenderId: "507739965749",
  appId: "1:507739965749:web:95e95fdf6f77b766"
};

firebase.initializeApp(firebaseConfig);

export const firebaseAuth = firebase.auth();
export const firebaseDb = firebase.firestore();