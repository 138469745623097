import React, { useState } from 'react';
import { setLoader } from '../../actions';
import { connect } from "react-redux";
import cx from 'classnames';
import './index.css';
import { routeCodes } from '../../config/routes';

const HomeLinks = ({ categories, handlePageChange, isFilm, dispatch }) => {
  const [loadedImages, setLoadedImages] = useState([]);
  const onLoad = index => {
    setLoadedImages([...loadedImages, index]);
    if (loadedImages.length + 1 === categories.length) {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 1500);
    }
  }

  const renderLinks = () => {
    return categories && categories.map((category, categoryIndex) => {
      const isCommercialsLink = categoryIndex < 4;
      const routeType = category.name.replace(/\s/g, '').toLowerCase();
      let categoryPage = isCommercialsLink ?
        `${routeCodes.COMMERCIAL.path}/${routeType}` :
        routeCodes[category.name.toUpperCase()].path;
      if (isFilm) {
        const filmWithoutSpaces = category.name.split(' ').join('_').toLowerCase();
        categoryPage = `/film-project/${filmWithoutSpaces}`;
      }

      return (
        <button
          className='HomeLink'
          onClick={() => handlePageChange(categoryPage)}
          key={categoryIndex}
        >
          <img
            onLoad={() => onLoad(categoryIndex)}
            src={category.image}
            alt={category.name}
            className='HomeLink-image'
          />
          <div className="HomeLink-inner">
            <span>{category.name}</span>
          </div>
          <div className='HomeLink-overlay' />
        </button>
      )
    })
  }

  const classes = cx('HomeLinks', isFilm && 'HomeLinks--film');

  return (
    <div className={classes}>
      {renderLinks()}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    isLoaderVisible: state.isLoaderVisible
  };
}

export default connect(mapStateToProps)(HomeLinks);