import React from "react";
import cx from "classnames";

import "./index.css";

const menuRoutes = [
  {
    name: "Action",
    link: "/commercial/action"
  },
  {
    name: "Comedy",
    link: "/commercial/comedy"
  },
  {
    name: "Children",
    link: "/commercial/children"
  },
  {
    name: "Health & Beauty",
    link: "/commercial/health&beauty"
  },
  {
    name: "Film",
    link: "/film"
  },
  {
    name: "Photography",
    link: "/photography"
  },
  {
    name: "Biography",
    link: "/biography"
  },
  {
    name: "Contact",
    link: "/contact"
  }
];

const MobileMenu = ({ isActive, handlePageChange }) => {
  const menuClasses = cx("MobileMenu", isActive && "MobileMenu--active");
  return (
    <div className={menuClasses}>
      <div className="MobileMenu-inner">
        {menuRoutes.map((item, index) => {
          return (
            <button
              onClick={() => handlePageChange(item.link)}
              className="MobileMenu-item"
              key={index}
            >
              {item.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default MobileMenu;
