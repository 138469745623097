import React, { Component } from 'react';
import { setLoader } from '../../actions';
import { connect } from "react-redux";
import './index.css';

class PhotographyList extends Component {
  state = {
    loadedImages: []
  }
  onLoad = index => {
    const { loadedImages } = this.state;
    const { data, dispatch } = this.props;

    this.setState({
      loadedImages: [...loadedImages, index]
    })

    if (loadedImages.length + 1 === data.length) {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 1500);
    }
  }

  render() {
    const { data, handlePageChange } = this.props;
    return (
      <div className="PhotographyList">
        {
          data && data.map((photo, index) => {
            return (
              <button
                onClick={() => handlePageChange(`/photo-project/${photo.title.toLowerCase()}`)}
                className='PhotographyList-item'
                key={index}
              >
                <img
                  className='PhotographyList-image'
                  src={photo.image}
                  alt={photo.title}
                  onLoad={() => this.onLoad(index)}
                />
                <div className="PhotographyList-content">
                  <div className='PhotographyList-title'>
                    <span>{photo.title}</span>
                  </div>
                  <div className="PhotographyList-overlay" />
                </div>
              </button>
            )
          })
        }
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    isLoaderVisible: state.isLoaderVisible
  };
}

export default connect(mapStateToProps)(PhotographyList);